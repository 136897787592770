import {
  DocumentNode,
  OperationVariables,
  useLazyQuery,
  useQuery,
} from '@apollo/client'
import { TadaDocumentNode } from 'gql.tada'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useCallback, useEffect, useRef } from 'react'

export * as ga from './ga'

export function toFixedScore(num: number) {
  return +(num * 100).toFixed()
}

export function exists<T = unknown>(value: T): value is NonNullable<T> {
  return (value ?? null) !== null
}

/**
 * Imperative version of Apollo's `useLazyQuery` which returns an async invocable action
 * @param {*} query Apollo query definition
 * @returns An async invocable action
 */
export function useImperativeQuery(query: DocumentNode) {
  const { refetch } = useQuery(query, { skip: true })

  const imperativelyCallQuery = ({
    variables,
  }: {
    variables: Partial<OperationVariables>
  }) => {
    return refetch(variables)
  }

  return [imperativelyCallQuery]
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export function convertObjectToTuple(object: { name: string; value: unknown }) {
  const { name, value } = object
  return [name, value]
}

export function getPillarsColors(): Map<string, string> {
  const $employees = '#B7DAF5'
  const $governance = '#0A2765'
  const $dei = '#227872'
  const $environment = '#0F9E93'
  const $community = '#1AD9B6'
  const $customers = '#4A608C'
  const $financials = '#1F78B4'
  const colors = new Map<string, string>()
  colors.set('Employees', $employees)
  colors.set('Environment', $environment)
  colors.set('Governance', $governance)
  colors.set('Community', $community)
  colors.set('DEI', $dei)
  colors.set('Customers', $customers)
  colors.set('Financials', $financials)

  return colors
}

export function parseBoolean(value: Maybe<string>): boolean {
  return value === 'true'
}

export function useShortPolling<TData, TVariables extends OperationVariables>(
  document: TadaDocumentNode<TData, TVariables>,
  options?: {
    pollInterval: number
  },
) {
  const [fetch, { error, data, startPolling, stopPolling }] =
    useLazyQuery(document)

  const polling = useRef<boolean>(false)

  const start = useCallback(
    (variables: TVariables) => {
      fetch({ variables })
      startPolling(options?.pollInterval ?? 1_000)
      polling.current = true
    },
    [fetch, options?.pollInterval, startPolling],
  )

  const stop = useCallback(() => {
    polling.current = false
    stopPolling()
  }, [stopPolling])

  return [
    start,
    stop,
    { polling: polling.current, error, data } as const,
  ] as const
}
